import React, { useState, useEffect, useCallback } from 'react'
import { PageProps } from 'gatsby'
import { useEmblaCarousel } from 'embla-carousel/react'

import Layout from '../../layout/Default'
import {
  Hero, HeroContainer, Introduction, IntroductionBodyContainer,
  IntroductionTitle, IntroductionBody, HeroButton, Truncate, ReadMore
} from '../../styles/index.styled'
import {
  ThumbnailContainer, ServiceContainer, ServiceTitle, ServiceContent, ServiceImages,
  ServiceImagesThumb, ServicesImagesThumbContainer, ServiceBanner, ServiceBannerImage,
  DescriptionIllustration, ServicesImagesContainer
} from '../../styles/layanan/index.styled'

import Navigation from '../../components/Navigation'
import ContactUs from '../../components/ContactUs'
import Footer from '../../components/Footer'
import { WhatsApp } from '../../components/Svg'

import PriceListImg from '../../assets/img/price-list.png'
import DefaultDescriptionImg from '../../assets/img/default-description-image.png'
import Meta from '../../utils/Meta'

import { PrevButton, NextButton } from '../../components/Carousel/button'

type ServicePageContext = {
  name: string;
  desc: string;
  hasPriceList: boolean;
  carouselImage: [],
  detailImage: string;
}

type ServicePageProps = PageProps<ServicePageContext>

const Index = (props: ServicePageProps): React.ReactElement => {
  const { name, desc, hasPriceList, carouselImage, detailImage } = props.pageContext as ServicePageContext
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [readMore, setReadMore] = useState(false)
  const [emblaRef, embla] = useEmblaCarousel({
    loop: true
  })
  const [emblaThumbsRef, emblaThumbs] = useEmblaCarousel({
    containScroll: 'keepSnaps',
    dragFree: true,
    selectedClass: ''
  })

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index)
    },
    [embla, emblaThumbs]
  )

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const detailImageDescription = detailImage ? require(`../../static/${detailImage}`) : DefaultDescriptionImg
  const carouselImages = carouselImage?.map(item => require(`../../static/${item}`)) ?? []

  const scrollPrev = useCallback(() => emblaThumbs && carouselImages && emblaThumbs.scrollTo(0), [emblaThumbs])
  const scrollNext = useCallback(() => emblaThumbs && carouselImages && emblaThumbs.scrollTo(carouselImages.length - 1), [emblaThumbs])

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return
    setSelectedIndex(embla.selectedScrollSnap())
    emblaThumbs.scrollTo(embla.selectedScrollSnap())
  }, [embla, emblaThumbs, setSelectedIndex])

  const checkButton = useCallback(() => {
    if (!emblaThumbs) return
    setPrevBtnEnabled(emblaThumbs.canScrollPrev())
    setNextBtnEnabled(emblaThumbs.canScrollNext())
  }, [emblaThumbs])

  useEffect(() => {
    if (!emblaThumbs) return
    checkButton()
    emblaThumbs.on('select', checkButton)
  }, [emblaThumbs, checkButton])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <Layout>
      <Meta
        title={`BearSih | ${name}`}
        description={desc}
        url={props.location.href}
      />
      <Navigation
        onClickService={() => window.location.replace('/#our-service')}
        onClickTestimoni={() => window.location.replace('/#testimoni')}
        onClickContact={() => window.location.replace('/#contact')}
      />
      <Hero style={{ paddingBottom: 120 }}>
        <ServiceContainer>
          <ServiceTitle>
            {name}
          </ServiceTitle>
          <ServiceContent>
            <ThumbnailContainer hasPriceList={hasPriceList}>
              <ServiceImages ref={emblaRef} hasPriceList={hasPriceList}>
                <ServicesImagesContainer hasPriceList={hasPriceList}>
                  {carouselImages.map((img, key) => (
                    <img key={key} src={img} />
                  ))}
                </ServicesImagesContainer>
              </ServiceImages>
              <ServiceImagesThumb ref={emblaThumbsRef} hasPriceList={hasPriceList}>
                <ServicesImagesThumbContainer>
                  {carouselImages.map((img, key) => (
                    <div key={key} style={{ opacity: `${selectedIndex === key ? '1' : '0.4'}` }}>
                      <img onClick={() => onThumbClick(key)} src={img} />
                    </div>
                  ))}
                </ServicesImagesThumbContainer>
              </ServiceImagesThumb>
              <div style={{ position: 'relative' }}>
                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
              </div>
            </ThumbnailContainer>
            {hasPriceList && (
              <ServiceBanner>
                <ServiceBannerImage src={PriceListImg} />
                <HeroButton onClick={() => window.open('https://api.whatsapp.com/send?phone=6287882036426')} style={{ marginTop: '0px' }}>
                  <WhatsApp size={16} />
                  <span style={{ marginLeft: 8 }}>Hubungi Kami</span>
                </HeroButton>
              </ServiceBanner>
            )}
          </ServiceContent>
        </ServiceContainer>
      </Hero>
      <Introduction>
        <HeroContainer>
          <DescriptionIllustration>
            <img src={detailImageDescription} style={{ width: '100%', height: 'auto' }} />
          </DescriptionIllustration>
          <IntroductionBodyContainer style={{ height: '100%' }}>
            <IntroductionTitle style={{ fontWeight: 'normal' }}>Deskripsi</IntroductionTitle>
            <IntroductionBody>
              <Truncate lineCount={readMore ? 100 : 10}>
                {desc && desc.split('\n').map((i, key) => (
                  <p key={key} style={{ marginTop: 0 }}>{i}</p>
                ))}
              </Truncate>
              <ReadMore from={'detail'} onClick={() => setReadMore(!readMore)}>
                {readMore ? 'Sembunyikan' : 'Lihat Selengkapnya'}
              </ReadMore>
            </IntroductionBody>
          </IntroductionBodyContainer>
        </HeroContainer>
      </Introduction>
      <div>
        <ContactUs />
      </div>
      <Footer />
    </Layout>
  )
}

export default Index
