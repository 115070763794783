import styled, { css } from 'styled-components'
import { HeroContainer } from '../index.styled'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface CarouselProps {
  hasPriceList?: boolean
}

const ServiceContainer = styled(HeroContainer)`
  flex-direction: column;
  align-items: flex-start;
`

const ServicePrice = styled.span`
  padding: 6px 16px;
  background: rgba(242, 204, 142, 0.25);
  border-radius: 16px;
  color: #F2CC8E;
  font-weight: 500;
  font-size: 12px;
`

const ServiceTitle = styled.h1`
  font-weight: bold;
  font-size: 36px;
  color: #F2CC8E;
  display: flex;
  align-items: center;

  ${ServicePrice} {
    margin-left: 16px;
  }
`

const ServiceContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`

const ThumbnailContainer = styled.div<CarouselProps>`
  width: 100%;

  @media(max-width: 1200px) {
    ${(props) => css`
      margin-bottom: ${props.hasPriceList ? '32px' : 0};
    `}
  }
  
  @media(min-width: 768px) {
    width: auto;
  }
`

const ServiceImages = styled.div<CarouselProps>`
  color: white;
  height: 360px;
  overflow: hidden;
  border-radius: 4px;
  max-width: 670px;
  transition: all .3s ease;
  
  @media(min-width: 768px) {
    max-width: 670px;
    
    
    ${(props) => css`
      max-width: ${props.hasPriceList ? '670px' : '100%'};
    `}
  }
`

const ServiceImagesThumb = styled.div<CarouselProps>`
  overflow: hidden;
  margin-top: 24px;
  max-width: 670px;
  transition: all .3s ease;
  
  ${(props) => css`
    max-width: ${props.hasPriceList ? '670px' : '100%'};
  `}

  @media(max-width: 1152px) {
    max-width: 670px;
  }
`

const ServicesImagesContainer = styled.div<CarouselProps>`
  display: flex;
  max-width: 670px;
  transition: all .3s ease;
  
  ${(props) => css`
    max-width: ${props.hasPriceList ? '670px' : '1104px'};
  `}

  @media(max-width: 1152px) {
    max-width: 670px;
  }

  img {
    position: relative;
    min-width: 100%;
    height: 360px;
    object-fit: cover;
    border-radius: 12px;
  }
`

const ServicesImagesThumbContainer = styled.div<CarouselProps>`
  display: flex;
  height: 80px;

  img {
    position: relative;
    height: 80px;
    width: 80px;
    margin-right: 12px;
    border-radius: 4px;
    object-fit: cover;
  }
`

interface ButtonProps {
  disabled?: boolean
}

const CarouselPrev = styled.div<ButtonProps>`
  position: absolute;
  border-radius: 100%;
  padding: 8px;
  width: 24px;
  height: 24px;
  outline: 0;
  cursor: pointer;
  bottom: 20px;
  background-color: #F2CC8E;
  left: -16px;
  border: 0;
  transition: all .3s ease;
  ${(props) => css`
    display: ${props.disabled ? 'none' : 'block'};
    cursor: ${props.disabled ? 'default' : 'pointer'};
  `}
`

const CarouselNext = styled.div<ButtonProps>`
  position: absolute;
  border-radius: 100%;
  padding: 8px;
  width: 24px;
  height: 24px;
  outline: 0;
  cursor: pointer;
  bottom: 20px;
  background-color: #F2CC8E;
  right: -16px;
  border: 0;
  transition: all .3s ease;
  ${(props) => css`
    display: ${props.disabled ? 'none' : 'block'};
    cursor: ${props.disabled ? 'default' : 'pointer'};
  `}
`
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const ServiceBanner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 24px;
  transition: all .3s ease;
  width: auto;
  
  @media(max-width: 768px) {
    justify-content: center;
  }

  @media(max-width: 476px) {
    padding: 0;
  }
`

const ServiceBannerImage = styled.img`
  border-radius: 4px;
  max-width: 100%;
  height: auto;
  margin-bottom: 32px;
  
  @media(min-width: 476px) {
    width: 320px;
    padding: 0;
  }
`

const DescriptionIllustration = styled.div`
  min-width: 296px;
  
  @media(max-width: 768px) {
    display: none;
  }
`

export default {}
export {
  ThumbnailContainer,
  ServiceContainer, ServiceTitle, ServicePrice, ServiceContent, ServiceImages, ServicesImagesContainer,
  ServiceBanner, ServiceBannerImage, DescriptionIllustration, ServicesImagesThumbContainer, ServiceImagesThumb, CarouselNext, CarouselPrev
}
