import React from 'react'
import {
  CarouselNext, CarouselPrev
} from '../../styles/layanan/index.styled'

interface Button {
  enabled: boolean,
  onClick: any
}

export const PrevButton = ({ enabled, onClick }: Button): React.ReactElement => (
  <CarouselPrev
    onClick={onClick}
    disabled={!enabled}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#3C405B" />
    </svg>
  </CarouselPrev>
)

export const NextButton = ({ enabled, onClick }: Button): React.ReactElement => (
  <CarouselNext
    onClick={onClick}
    disabled={!enabled}
  >
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.59 7.41L10 6L16 12L10 18L8.59 16.59L13.17 12L8.59 7.41Z" fill="#3C405B" />
    </svg>
  </CarouselNext>
)
